import React from "react";
import { navigate } from "gatsby-link";
import Layout from "../../components/Layout";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";

function encode(data) {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

export default class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isValidated: false };
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleRecaptcha = (value) => {
        this.setState({ "g-recaptcha-response": value });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({
                "form-name": form.getAttribute("name"),
                ...this.state,
            }),
        })
            .then((res) => {
                navigate(form.getAttribute("action"));
            })
            .catch((error) => alert("There was an error submitting the form. Please try again."));
    };

    render() {
        return (
            <Layout>
                <Helmet>
                    <title>Contact Your Editors: Read Panda Wants To Hear From You</title>
                    <meta
                        name="description"
                        content="Contact us for a quote. Our services are tailor-made to the information you provide us with. Just want to say hi? We’ll be happy to hear from you."
                    />
                </Helmet>
                <section className="section">
                    <div className="container">
                        <div className="content">
                            <h1 className="has-text-black">Contact us</h1>
                            <p className="has-text-black">
                                We will be happy to provide you with a quote or answer any queries you may
                                have. Just fill out the form below and we’ll be in touch as soon as we can.
                            </p>
                            <form
                                name="contact"
                                method="post"
                                action="/contact/thanks/"
                                data-netlify="true"
                                data-netlify-honeypot="bot-field"
                                data-netlify-recaptcha="true"
                                onSubmit={this.handleSubmit}
                            >
                                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                                <input type="hidden" name="form-name" value="contact" />
                                <div hidden>
                                    <label>
                                        Don’t fill this out:{" "}
                                        <input name="bot-field" onChange={this.handleChange} />
                                    </label>
                                </div>
                                <div className="field">
                                    <label className="label has-text-black" htmlFor={"name"}>
                                        Your name
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            type={"text"}
                                            name={"name"}
                                            onChange={this.handleChange}
                                            id={"name"}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label has-text-black" htmlFor={"email"}>
                                        Email
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            type={"email"}
                                            name={"email"}
                                            onChange={this.handleChange}
                                            id={"email"}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label has-text-black" htmlFor={"message"}>
                                        Message
                                    </label>
                                    <div className="control">
                                        <textarea
                                            className="textarea"
                                            name={"message"}
                                            onChange={this.handleChange}
                                            id={"message"}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <div className="control">
                                        <ReCAPTCHA
                                            sitekey={process.env.GATSBY_RECAPTCHA_KEY}
                                            id={"recaptcha"}
                                            name={"recaptcha"}
                                            onChange={this.handleRecaptcha}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <button
                                        disabled={!this.state["g-recaptcha-response"]}
                                        className="button is-warning"
                                        type="submit"
                                    >
                                        Send
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </Layout>
        );
    }
}
